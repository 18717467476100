import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Heading, Text, Box } from "insites-ui"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <section className="text-page-section">
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-lg-12 px-0"}>
            <Heading>Privacy Policy</Heading>
            <Box
              backgroundColor="#ffffff"
              boxShadow="0 0.5rem 1rem rgba(0, 0, 0, 0.05)"
              borderRadius="0.5rem"
              padding="0.5rem 2rem"
            >
              <Heading as="h4" size="sm">
                Introduction
              </Heading>
              <Text>
                It is the &quot;Supplier&quot; (Investment Property Tracker)
                policy to respect your privacy regarding any information we may
                collect while operating our website and software that is owned
                or operated by the Supplier and its related entities.
              </Text>
              <Text>
                This Privacy Policy applies to{" "}
                <a href="https://www.investmentpropertytracker.com.au">
                  www.investmentpropertytracker.com.au
                </a>{" "}
                (hereinafter, &quot;us&quot;, &quot;we&quot;, or
                &quot;www.investmentpropertytracker.com.au&quot;). We respect
                our users&lsquo; privacy and are committed to protecting
                personally identifiable information or data that you may provide
                to us through our Site or Software. We have adopted this privacy
                policy (&quot;Privacy Policy&quot;) to explain what information
                may be collected on our Website or Software, how we use this
                information, and under what circumstances we may disclose the
                information to third parties. This Privacy Policy applies only
                to information we collect through the Website and Software and
                does not apply to our collection of information from other
                sources.
              </Text>
              <Text>
                This Privacy Policy, together with the Terms and conditions
                posted on our Website, set out the general rules and policies
                that govern the use of our Website which may be dependent on the
                activities that a user may undertake on our Website or Software
                which may require you to agree to additional terms and
                conditions prior to use.
              </Text>
              <Text>
                Investment Property Tracker collects personally and
                non-personally identifying information such as the browser type,
                language preference, referring site, and the date and time of
                each visitor request, data that you may have provided to the
                Website or Software which may include personal data or
                information. Investment Property Tracker&lsquo;s purpose in
                collecting this information is to better understand how
                Investment Property Tracker&lsquo;s visitors use its Website or
                Software. From time to time, Investment Property Tracker may
                release non-personally-identifying information to third parties
                on a de-identified basis.
              </Text>
              <Heading as="h4" size="sm">
                Gathering of Personally Identifying Information
              </Heading>
              <Text>
                Certain users or visitors to Investment Property Tracker&lsquo;s
                Websites or Software choose to interact with Investment Property
                Tracker in ways that could require Investment Property Tracker
                to gather, retain and / or use personally-identifying
                information. The amount and type of information that Investment
                Property Tracker gathers depends on the nature of the
                interaction.
              </Text>
              <Text>
                The security of your Personal Information is important to us.
                However, we note that no method of transmission over the
                Internet, or method of electronic storage is 100% secure. While
                we do strive to use commercially acceptable means to protect
                your Personal Information, we cannot guarantee its absolute
                security.
              </Text>
              <Text>
                Our Website or Software may contain links to external websites
                that are not operated by us. If you click on a third party link,
                you will be directed to that third party&lsquo;s site. We
                strongly advise you to review the Privacy Policy and terms and
                conditions of every site you visit.
              </Text>
              <Text>
                We have no control over, and assume no responsibility for the
                content, privacy policies or practices of any third party sites,
                products or services.
              </Text>
              <Heading as="h4" size="sm">
                Protection of some Personally Identifying Information
              </Heading>
              <Text>
                Investment Property Tracker discloses potentially
                personally-identifying and personally-identifying information
                only to those of its employees, contractors and affiliated
                organizations that (i) need to know that information in order to
                process it on Investment Property Tracker&lsquo;s behalf or to
                provide services available at Investment Property
                Tracker&lsquo;s Website or Software, and (ii) that have agreed
                not to disclose it to others. Some of those employees,
                contractors and affiliated organisations may be located outside
                of your home country. By using Investment Property
                Tracker&lsquo;s Website or Software, you consent to the transfer
                of such information to them. Other than to its employees,
                contractors and affiliated organizations, as described above,
                Investment Property Tracker discloses potentially
                personally-identifying and personally-identifying information
                only in response to a court order or other governmental request,
                or when Investment Property Tracker believes in good faith that
                disclosure is reasonably necessary to protect the property or
                rights of Investment Property Tracker, third parties or the
                public at large.
              </Text>
              <Text>
                If you are a registered user of{" "}
                <a href="https://www.investmentpropertytracker.com.au">
                  www.investmentpropertytracker.com.au
                </a>{" "}
                and have supplied your email address, Investment Property
                Tracker may occasionally send you an email to tell you about new
                features, ask for your feedback, or keep you in the loop with
                news about our Website or Software or any other products. We
                expect to keep this type of email to a minimum. Investment
                Property Tracker takes all measures reasonably necessary to
                protect against the unauthorized access, use, alteration or
                destruction of potentially personally-identifying and
                personally-identifying information.
              </Text>
              <Text>
                Investment Property Tracker may collect statistics about the
                behaviour of visitors to its website. Investment Property
                Tracker may display this information publicly or provide it to
                others.
              </Text>
              <Text>
                To perfect our Website and Software, Investment Property Tracker
                may use &quot;Cookies&quot;, which is similar technologies and
                services provided by others to display personalised content,
                appropriate advertising and store your preferences on your
                computer. A cookie is a string of information that a website
                stores on a visitor&lsquo;s computer, and that the
                visitor&lsquo;s browser provides to the website each time the
                visitor returns. Investment Property Tracker may use cookies to
                help the Supplier identify and track visitors, their usage of{" "}
                <a href="https://www.investmentpropertytracker.com.au">
                  www.investmentpropertytracker.com.au
                </a>
                , and their website access preferences. Investment Property
                Tracker visitors who do not wish to have cookies placed on their
                computers should set their browsers to refuse cookies before
                using Investment Property Tracker&lsquo;s websites. This may
                impact functionality of our Website or Software. We are not
                responsible for this impact. By continuing to navigate our
                website without changing your cookie settings, you hereby
                acknowledge and agree to Investment Property Tracker&lsquo;s use
                of cookies.
              </Text>
              <Text>
                Those who engage in transactions with Investment Property
                Tracker – by purchasing Investment Property Tracker&lsquo;s
                services or products, are asked to provide additional
                information, including as necessary the personal and financial
                information required to process those transactions. In each
                case, Investment Property Tracker collects such information only
                insofar as is necessary or appropriate to fulfil the purpose of
                the visitor&lsquo;s interaction with Investment Property
                Tracker. Investment Property Tracker does not disclose
                personally-identifying information other than as described
                below. And visitors can always refuse to supply
                personally-identifying information, with the caveat that it may
                prevent them from engaging in certain website-related
                activities.
              </Text>
              <Text>
                If Investment Property Tracker, or substantially all of its
                assets, were acquired, or in the unlikely event that Investment
                Property Tracker goes out of business or enters bankruptcy, user
                information would be one of the assets that is transferred or
                acquired by a third party. You acknowledge that such transfers
                may occur, and that any acquirer of Investment Property Tracker
                may continue to use your personal information as set forth in
                this policy.
              </Text>
              <Heading as="h4" size="sm">
                Privacy Policy Changes
              </Heading>
              <Text>
                Although most changes are likely to be minor, Investment
                Property Tracker may change its Privacy Policy from time to
                time, and in Investment Property Tracker&lsquo;s sole
                discretion. Investment Property Tracker encourages visitors to
                frequently check this page for any changes to its Privacy
                Policy. Your continued use of this site after any change in this
                Privacy Policy will constitute your acceptance of such change.
              </Text>
            </Box>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PrivacyPage
